<!-- src/components/TopMenu.vue -->
<template>
  <v-app-bar app color="topAppBar" light data-cy="app-bar">
    <img class="logo" src="@/assets/viaphoton-logo.svg" alt="viaphoton logo" @click="navigateTo('Landing')" data-cy="logo" />
    <v-toolbar-title class="text-center title-text" data-cy="toolbar-title">
      | &nbsp;BOM
      <span>generator</span>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <!-- <ThemeSwitcher style="margin-right: 30px; margin-top: 20px" /> -->
    <div class="usersnap">
      <button class="feedback-btn" onclick="window.Usersnap.logEvent('openusersnap')">Feedback</button>
    </div>

    <!-- Profile Menu -->
    <v-menu min-width="200px" rounded offset-y data-cy="profile-menu">
      <template v-slot:activator="{ props }">
        <v-btn icon v-bind="props" data-cy="profile-button">
          <v-avatar color="grey" size="large" data-cy="profile-avatar">
            <span class="text-h6" data-cy="profile-initials">
              {{ initials }}
            </span>
          </v-avatar>
        </v-btn>
      </template>
      <v-card data-cy="profile-card">
        <v-card-text data-cy="profile-card-text">
          <div class="mx-auto text-center" data-cy="profile-content">
            <v-avatar color="grey" data-cy="profile-avatar-large">
              <span class="text-h6" data-cy="profile-initials-large">
                {{ initials }}
              </span>
            </v-avatar>
            <h3 data-cy="user-name">{{ user?.name }}</h3>
            <p class="text-caption mt-1" data-cy="user-email">
              {{ user?.email }}
            </p>
            <v-divider class="my-3" data-cy="profile-divider"></v-divider>
            <v-btn variant="text" rounded @click="handleProfileOptionClick('Profile')" data-cy="profile-button-profile">Profile</v-btn>
            <v-divider class="my-3" data-cy="logout-divider"></v-divider>
            <v-btn variant="text" rounded @click="handleProfileOptionClick('Logout')" data-cy="profile-button-logout">Logout</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script lang="ts">
  import { defineComponent, computed, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  import { useAuth0 } from '@auth0/auth0-vue';
  import ThemeSwitcher from '@/components/ThemeSwitcher.vue';

  export default defineComponent({
    name: 'TopMenu',
    components: {
      ThemeSwitcher,
    },
    setup() {
      const router = useRouter();
      const { user } = useAuth0();

      const initials = computed(() => {
        if (user.value && user.value.name) {
          const names = user.value.name.split(' ');
          return names.map((n) => n[0]).join('');
        }
        return '';
      });

      const navigateTo = (page: string) => {
        router.push({ name: page });
      };

      const handleProfileOptionClick = (page: string) => {
        router.push({ name: page });
      };

      let markerWidget: any;

      const openMarkerWidget = () => {
        if (markerWidget) {
          markerWidget.show();
        }
      };

      return {
        navigateTo,
        user,
        initials,
        handleProfileOptionClick,
        openMarkerWidget,
      };
    },
  });
</script>

<style scoped>
  .logo {
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
  }
  .text-center {
    margin: 0 auto;
  }
  .title-text {
    position: absolute;
    left: 140px;
    font-size: 22px;
    font-weight: 500;
  }
  .title-text span {
    font-size: 20px;
    font-weight: 400;
  }
  .profile-avatar {
    margin-right: 10px;
    cursor: pointer;
  }

  .usersnap {
    margin-right: 30px;
    margin-top: -32px;
  }
  .feedback-btn {
    background-color: orange;
    padding: 4px 20px;
    border: none;
    color: white;
    font-size: 14px;
    cursor: pointer;
    border-radius: 0px 0px 10px 10px;
    transition: 0.3s;
  }
  .feedback-btn:hover {
    background-color: orange;
    color: #333e50;
  }
</style>
