<!-- src/components/ThemeSwitcher.vue -->
<template>
  <v-switch data-cy="theme-switcher" v-model="isDark" :label="`${isDark ? 'Light' : 'Dark'}`" @change="toggleTheme"></v-switch>
</template>

<script lang="ts">
  import { defineComponent, computed } from 'vue';
  import { useTheme } from 'vuetify/lib/framework.mjs';

  export default defineComponent({
    name: 'ThemeSwitcher',
    setup() {
      const theme = useTheme();

      const isDark = computed({
        get: () => theme.global.name.value === 'dark',
        set: (value) => {
          theme.global.name.value = value ? 'dark' : 'light';
        },
      });

      const toggleTheme = () => {
        theme.global.name.value = isDark.value ? 'dark' : 'light';
      };

      return {
        isDark,
        toggleTheme,
      };
    },
  });
</script>
