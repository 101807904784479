<!-- src/views/materials/MaterialsModal.vue -->
<template>
  <v-dialog v-model="modal" max-width="1100px" data-cy="modal" persistent>
    <v-card data-cy="modal-card">
      <v-card-title class="modal-title" data-cy="modal-title">
        <span class="text-h5">{{ modalTitle }}</span>
        <v-icon small @click="closeModal" data-cy="close-icon" style="right: 10px; top: 10px; position: absolute">mdi-close</v-icon>
      </v-card-title>
      <v-card-text data-cy="modal-content">
        <h4>Basic data</h4>
        <v-form ref="form" class="scrollable-modal" data-cy="form">
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-autocomplete
                label="Odoo Raw Material"
                :items="odooMaterialsOptions"
                v-model="selected.odooMaterial"
                variant="underlined"
                item-title="display_name"
                data-cy="select-odoo-material"
                clearable
                return-object
                :loading="loadingMaterials"
                :search-input.sync="searchOdooMaterials"
                @update:search="onSearchOdooMaterials"
                @update:modelValue="onOdooMaterialSelected"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4">
              <v-autocomplete
                label="Product Family Code"
                :items="productFamilyOptions"
                v-model="selected.productFamilyCode"
                variant="underlined"
                item-title="label"
                data-cy="select-product-family"
                clearable
                return-object
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4">
              <v-autocomplete
                label="Logic Module"
                :items="filteredComponents"
                v-model="selected.componentType"
                variant="underlined"
                item-title="name"
                data-cy="select-product-family"
                clearable
                return-object
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select :items="genUnitOptions" v-model="selected.uomId" return-object clearable data-cy="form-unit" variant="underlined">
                <template #item="data">
                  <v-list-subheader v-if="data.props && data.props.header" class="uom-select-header">
                    <b>{{ data.props.header }}</b>
                  </v-list-subheader>
                  <v-divider v-else-if="data.props && data.props.divider" />
                  <v-list-item v-else v-bind="data.props"></v-list-item>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <div style="margin-top: 16px">
            <h4>Odoo data</h4>
            <v-row style="position: relative">
              <v-col cols="12" sm="4" style="cursor: not-allowed">
                <v-text-field
                  :value="formatPrice(selected.options.quantityInStock)"
                  v-model="selected.options.quantityInStock"
                  label="Availability*"
                  type="text"
                  inputmode="decimal"
                  variant="underlined"
                  hint="Indicates the inventory information in Odoo"
                  :loading="loadingDataFromPM"
                  readonly
                  :style="{ opacity: 0.7, 'pointer-events': 'none', cursor: 'not-allowed' }"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" style="cursor: not-allowed">
                <v-text-field
                  :value="formatPrice(selected.options.pricePerUnit)"
                  v-model="selected.options.pricePerUnit"
                  suffix="USD"
                  label="Cost per unit*"
                  type="text"
                  inputmode="decimal"
                  variant="underlined"
                  hint="Cost of this raw material in Odoo"
                  :loading="loadingDataFromPM"
                  readonly
                  :style="{ opacity: 0.7, 'pointer-events': 'none', cursor: 'not-allowed' }"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" style="cursor: not-allowed">
                <v-select
                  :items="[
                    { value: 'active', label: '✅ Active' },
                    { value: 'archived', label: '🗃️ Archived' },
                  ]"
                  v-model="selected.options.status"
                  label="Status*"
                  variant="underlined"
                  item-value="value"
                  item-title="label"
                  hint="Indicates the status of the raw material in Odoo"
                  :loading="loadingDataFromPM"
                  readonly
                  :style="{ opacity: 0.7, 'pointer-events': 'none', cursor: 'not-allowed' }"
                ></v-select>
              </v-col>
              <div class="odoo-footer">
                <v-btn v-if="showSyncButton" class="btn-sync" @click="singleSync" data-cy="sync-button">Sync With Odoo</v-btn>
                <span class="asterisk-info">* - These fields are automatically synchronized with Odoo. They cannot be modified manually.</span>
              </div>
            </v-row>
          </div>
        </v-form>
      </v-card-text>

      <v-card-text>
        <v-data-table :headers="attributesHeaders" :items="selected.attributes" item-value="name" class="elevation-1 custom-table" data-cy="attributes-table">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Attributes</v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:item.name="{ item }">
            <div>
              {{ item.name }}
              <span v-if="item.isRequired" style="color: red">*</span>
            </div>
          </template>
          <template v-slot:item.value="{ item }">
            <div>
              <v-text-field
                v-if="!item.values || item.values.length === 0"
                v-model="(item as Attribute).value!.value"
                variant="underlined"
                data-cy="enum-description-input"
                :loading="loadingDataFromPM"
              ></v-text-field>

              <v-combobox
                v-else
                v-model="(item as Attribute).value!.value"
                :items="item.values ? item.values.slice().sort((a: any, b: any) => a.value.localeCompare(b.value)) : []"
                variant="underlined"
                item-title="value"
                item-value="value"
                data-cy="enum-select"
                clearable
                :loading="loadingDataFromPM"
                @update:modelValue="(newValue) => handleValueChange(item, newValue)"
              ></v-combobox>
            </div>
          </template>
          <template v-slot:item.uom="{ item }">
            {{ getUomNameById(item.uomId) }}
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-text class="options">
        <span v-if="showRequiredText" color="red" class="optional-info">* - This raw material will not be usable for BOM generation if any required field is missing.</span>
        <v-row>
          <v-col cols="12" sm="12">
            <v-checkbox v-model="selected.options.complete" label="Attribute data is complete" disabled></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions data-cy="modal-actions" class="btn-modal-bottom">
        <v-spacer></v-spacer>
        <v-btn class="btn-cancel" @click="closeModal" data-cy="cancel-button">Cancel</v-btn>
        <v-btn class="btn-save" @click="saveMaterial(modalTitle!)" data-cy="save-button">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { OdooMaterial, ProductFamily, Attribute, uom } from '@/types/materials';

  export default defineComponent({
    name: 'MaterialsModal',
    props: {
      modal: Boolean,
      modalTitle: String,
      odooMaterialsOptions: Array as PropType<OdooMaterial[]>,
      selected: {
        type: Object as PropType<{
          odooMaterial: OdooMaterial | null;
          productFamilyCode: ProductFamily | null;
          componentType: any;
          attributes: Attribute[];
          uomId: uom | null;
          options: any;
        }>,
        default: () => ({
          odooMaterial: null,
          productFamilyCode: null,
          componentType: null,
          attributes: [],
          uomId: null,
          options: [],
        }),
      },
      productFamilyOptions: Array as PropType<ProductFamily[]>,
      filteredComponents: Array,
      genUnitOptions: Array,
      loadingMaterials: Boolean,
      searchOdooMaterials: String,
      loadingDataFromPM: Boolean,
      unitOptions: {
        type: Array as PropType<any[]>,
        required: true,
        default: () => [],
      },
    },
    data() {
      return {
        attributesHeaders: [
          { title: 'Name', value: 'name', width: '30%' },
          { title: 'Description', value: 'description', width: '30%' },
          { title: 'Value', value: 'value', width: '30%' },
          { title: 'Uom', value: 'uom', width: '10%' },
        ],
        options: [],
      };
    },
    computed: {
      showSyncButton(): boolean {
        return this.modalTitle !== 'Create Material';
      },
      showRequiredText(): boolean {
        return this.selected?.attributes ? this.selected?.attributes.length > 0 : false;
      },
    },
    methods: {
      getUomNameById(uomId: any) {
        for (const category of this.unitOptions) {
          const unit = category.value?.find((u: any) => u.id === uomId);
          if (unit) {
            return unit.name;
          }
        }
        return '';
      },

      closeModal() {
        this.$emit('close');
      },
      saveMaterial(modalTitle: string) {
        this.$emit('save', modalTitle);
      },
      singleSync() {
        this.$emit('sync');
      },
      onSearchOdooMaterials(value: string) {
        this.$emit('search-odoo-materials', value);
      },
      onOdooMaterialSelected(value: OdooMaterial) {
        this.$emit('odoo-material-selected', value);
      },
      formatPrice(price: number) {
        return Number(price).toLocaleString('en-US', {
          maximumFractionDigits: 4,
          useGrouping: true,
        });
      },
      handleValueChange(item: Attribute, newValue: any) {
        if (newValue) {
          item.value!.value = newValue;
        } else {
          item.value!.value = '';
        }
      },
    },
  });
</script>

<style scoped>
  .modal-title {
    background-color: #3b516b;
    color: white;
  }

  .asterisk-info {
    font-size: 12px;
    color: #adadad;
  }

  .optional-info {
    font-size: 12px;
    color: red;
  }

  .odoo-footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 0 0 16px;
    gap: 8px;
  }

  /* Sync button */
  .btn-sync {
    background-color: #1976d2;
    color: white;
    border: 2px solid #1976d2;
    border-radius: 4px;
    font-weight: 500;
    padding: 1px 16px;
  }

  .btn-sync:hover {
    background-color: white;
    color: #1976d2;
    border: 2px solid #1976d2;
  }
</style>
