<!-- src/views/components/Components.vue -->
<template>
  <v-container fluid style="padding-top: 0px">
    <v-alert class="mb-3" v-if="error" type="error" variant="tonal" title="Error">
      {{ errorMessage }}
    </v-alert>
    <v-card class="pa-3">
      <v-tabs v-model="activeTab" style="border-bottom: 2px solid">
        <v-tab active data-cy="tab-overview">Overview</v-tab>
        <v-spacer></v-spacer>
        <v-select
          class="mr-3 filter-select"
          max-width="200"
          v-model="tableFilter"
          :items="tableFilterData"
          variant="underlined"
          data-cy="form-value-mode"
          prepend-inner-icon="mdi-filter"
        ></v-select>
        <v-text-field
          class="mr-3"
          max-width="200"
          v-model="searchQuery"
          density="compact"
          label="Search"
          prepend-inner-icon="mdi-magnify"
          variant="underlined"
          hide-details
          single-line
          data-cy="search-input"
        ></v-text-field>
        <v-btn class="btn-orange" @click="openModal('create')" data-cy="new-button">New</v-btn>
      </v-tabs>

      <div v-if="activeTab === 0" data-cy="tab-content-overview">
        <v-data-table
          :headers="tableHeaders"
          :items="filteredComponents"
          item-value="name"
          class="custom-table"
          data-cy="data-table"
          loading-text="Loading... Please wait"
          :loading="loadingData"
          v-model:items-per-page="itemsPerPage"
          :items-per-page-options="itemsPerPageOptions"
        >
          <template v-slot:item.name="{ item }">
            <n-ellipsis style="max-width: 240px">{{ item.name }}</n-ellipsis>
          </template>
          <template v-slot:item.description="{ item }">
            <n-ellipsis style="max-width: 240px">{{ item.description }}</n-ellipsis>
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip :color="item.status === 'OK' ? 'green' : 'red'" dark>
              {{ item.status }}
            </v-chip>
          </template>
          <template v-slot:item.category="{ item }">
            <span>{{ item.category }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="controls-wrapper">
              <span @click="handleCopy(item.id)">
                <v-tooltip activator="parent" location="top">Copy GUID</v-tooltip>
                <i class="fa-duotone fa-solid fa-copy"></i>
              </span>
              <span @click="openModal('edit', item)"><i class="fa-duotone fa-solid fa-pen-to-square"></i></span>
              <span @click="confirmDeletion(item)"><i class="fa-duotone fa-solid fa-trash"></i></span>
            </div>
          </template>
        </v-data-table>
      </div>

      <div v-else data-cy="tab-content-details">
        <div class="text-center">
          <h2>Details</h2>
          <p>Information on Construction</p>
        </div>
      </div>

      <LogicModal
        v-if="isModalOpen"
        :title="modalTitle"
        :current-item="currentItem"
        :product-family-options="productFamilyOptions"
        :inherit-options="inheritOptions"
        :available-attributes="attributesData"
        :available-components="components"
        :available-uom="unitOptions"
        @close="closeModal"
        @save="saveItem"
      />

      <Dialogs :deleteDialog="deleteDialog" @closeDeleteDialog="closeDeleteDialog" @confirmDeleteItem="confirmDelete" />
    </v-card>
  </v-container>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import { copyToClipboard } from '@/services/clipboard';
  import LogicModal from '@/views/logics/LogicModal.vue';
  import { ComponentItem, AttributeItem } from '@/types/components';
  import { UnitCategory } from '@/types/attributes';
  import { ProductFamilyOption } from '@/types/productFamily';
  import { useAttributesStore } from '@/store/attributesStore';
  import { useComponentsTypeStore } from '@/store/componentsTypesStore';
  import { useUserPreferencesStore } from '@/store/userPreferencesStore';
  import Dialogs from '@/components/Dialogs.vue';
  import etcService from '@/services/etcService';
  import attributeService from '@/services/attributeService';
  import componentService from '@/services/componentService';
  import { c } from 'naive-ui';

  export default defineComponent({
    components: {
      LogicModal,
      Dialogs,
    },

    data() {
      return {
        error: false,
        errorMessage: '',
        activeTab: 0,
        searchQuery: '',
        isModalOpen: false,
        deleteDialog: false,
        modalTitle: '',
        deleteDialogText: '',
        deleteButton: true,
        currentItem: this.resetForm(),
        itemToDelete: null as ComponentItem | null,
        loadingData: false,
        tableHeaders: [
          { title: 'Logic Module', value: 'name', sortable: true, width: '30%' },
          { title: 'Description', value: 'description', sortable: true, width: '30%' },
          { title: 'References', value: ' ', sortable: true, width: '20%' },
          { title: 'Category', value: 'category', sortable: true, width: '10%' },
          { title: 'Actions', value: 'actions', sortable: false, width: '10%' },
        ],
        productFamilyOptions: [] as ProductFamilyOption[],
        components: [] as ComponentItem[],
        inheritOptions: [],
        attributesData: [] as AttributeItem[],
        getProductFamilyCodeData: [],
        tableFilter: 'All',
        tableFilterData: ['All', 'Logic', 'Raw material', 'Finished good'],
        itemsPerPage: 10,
        itemsPerPageOptions: [10, 25, 50, 100, -1],
        unitOptions: [] as UnitCategory[],
      };
    },
    watch: {
      tableFilter(newFilter) {
        this.saveUserPreferences({ tableFilter: newFilter });
      },
      itemsPerPage(newItemsPerPage) {
        this.saveUserPreferences({ itemsPerPage: newItemsPerPage });
      },
    },
    computed: {
      filteredComponents(): ComponentItem[] {
        let filtered = this.components;

        // Apply category filter if it's not 'All'
        if (this.tableFilter !== 'All') {
          filtered = filtered.filter((component) => component.category === this.tableFilter);
        }

        // Apply search filter
        if (this.searchQuery) {
          filtered = filtered.filter((component) => Object.values(component).some((value) => String(value).toLowerCase().includes(this.searchQuery.toLowerCase())));
        }

        return filtered;
      },
    },

    mounted() {
      this.getComponentsList();
      this.getAttributesList();
      this.getProductFamilyCode();
      this.getUnits();
      this.restoreUserPreferences();
    },

    methods: {
      async getAttributesList() {
        try {
          const attributesStore = useAttributesStore();
          const sortedAttributes = await attributeService.getAttributesList();
          this.attributesData = sortedAttributes;
          attributesStore.setAttributesData(sortedAttributes);
        } catch (error) {
          console.error('Failed to fetch attributes:', error);
        }
      },

      async getProductFamilyCode() {
        try {
          const response = await etcService.getProductFamilyCode();
          if (response) {
            this.productFamilyOptions = response
              .map((item: any) => {
                let label = `${item.code} - ${item.label}`;
                if (label.length > 60) {
                  label = label.substring(0, 57) + '...';
                }
                return {
                  id: item.id,
                  code: item.code,
                  label,
                  attributes: item.attributes,
                  segment: item?.segment,
                };
              })
              .sort((a: any, b: any) => a.code.localeCompare(b.code));
          }
        } catch (error: any) {
          console.error('Failed to fetch product family code:', error);
        }
      },

      async getUnits() {
        try {
          this.unitOptions = await etcService.getUnits();
        } catch (error) {
          this.$log.showError(`Failed to fetch units: ${error}`);
        }
      },

      async openModal(action: 'create' | 'edit', item: ComponentItem | null = null) {
        this.modalTitle = action === 'create' ? 'Create Logic Module' : 'Edit Logic Module';
        this.currentItem = item ? { ...item } : this.resetForm();
        this.isModalOpen = true;
      },

      closeModal() {
        this.isModalOpen = false;
      },

      async saveItem() {
        try {
          if (!this.currentItem.id) {
            await this.createComponent();
          } else {
            await this.updateComponent(this.currentItem.id);
          }
        } catch (error: any) {
          console.error('Failed to save component', error);
        }
      },

      confirmDeletion(item: ComponentItem) {
        if (item.components > 0) {
          this.deleteDialogText = `The Logic Module can’t be deleted, because it used in the following Materials : {list of Materials}`;
          this.deleteButton = false;
          this.itemToDelete = item;
          this.deleteDialog = true;
        } else {
          this.deleteDialogText = `Are you sure you want to delete <b>${item.name}</b> ?<br> The operation can’t be undone`;
          this.deleteButton = true;
          this.itemToDelete = item;
          this.deleteDialog = true;
        }
      },

      closeDeleteDialog() {
        this.deleteDialog = false;
        this.itemToDelete = null;
      },

      async confirmDelete() {
        if (this.itemToDelete) {
          await this.deleteComponent(this.itemToDelete.id!);
          this.components = this.components.filter((component) => component.id !== this.itemToDelete!.id);
          this.closeDeleteDialog();
        }
      },

      resetForm(): ComponentItem {
        return {
          type: '',
          name: '',
          components: 0,
          description: '',
          noMaterials: 0,
          status: '',
          series: '',
          inheritFrom: [],
          image: '',
          contains: [],
          attributes: [],
          quantity_formula: '',
          category: '',
          componentTypeName: '',
        };
      },

      async getComponentsList() {
        this.loadingData = true;
        const componentTypesStore = useComponentsTypeStore();
        try {
          const response = await componentService.getComponentsList();
          this.components = response;
          componentTypesStore.setComponentsTypesData(response);
        } catch (error) {
          this.$log.showError(`Failed to fetch component: ${error}`);
        } finally {
          this.loadingData = false;
        }
      },
      async createComponent() {
        try {
          const transformedItem = this.transformComponentData(this.currentItem);
          await componentService.createComponent(transformedItem);
          this.showSuccessMessage('Successfully added');
          this.closeModal();
          this.getComponentsList();
        } catch (error: any) {
          this.showErrorMessage(error);
        }
      },

      async updateComponent(id: string) {
        try {
          const transformedItem = this.transformComponentData(this.currentItem);
          await componentService.updateComponent(id, transformedItem);
          this.showSuccessMessage('Successfully updated');
          this.closeModal();
          this.getComponentsList();
        } catch (error: any) {
          this.showErrorMessage(error);
        }
      },

      async deleteComponent(id: string) {
        try {
          await componentService.deleteComponent(id);
          this.getComponentsList();
        } catch (error) {
          this.$log.showError(`Failed to delete component ${id}: ${error}`);
        }
      },

      transformComponentData(item: ComponentItem) {
        const transformedData: any = {
          name: item.name,
          inheritsFrom: item.inheritFrom.map((inherit: any) => inherit.id),
          attributes: item.attributes.map((attribute: any) => ({
            attributeId: attribute.id,
            value: attribute.value || undefined,
            comparison: attribute.comparison || undefined,
          })),
          contains: item.contains.map((item: any) => ({
            componentTypeId: item.id,
            qty: item.qty,
          })),
        };

        if (item.productFamilyCode?.id || item.productFamilyCode?.code || item.productFamilyCode?.label) {
          transformedData.productFamily = {
            id: item.productFamilyCode?.id,
            code: item.productFamilyCode?.code,
            name: item.productFamilyCode?.label,
            segment: item.productFamilyCode?.segment,
          };
        }

        if (item.description) {
          transformedData.description = item.description;
        }

        return transformedData;
      },
      showSuccessMessage(message: string) {
        this.$swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: message,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      },

      showErrorMessage(error: any) {
        const errorMessage = error.response?.data?.message || error.message || 'An unknown error occurred';
        this.$log.showError(`Changes were not saved due to the following error: ${errorMessage}`);
      },

      async handleCopy(id: any) {
        try {
          await copyToClipboard(id, this.$swal);
        } catch (error) {
          console.error('Copy operation failed:', error);
        }
      },

      saveUserPreferences(preferences: { tableFilter?: string; itemsPerPage?: number }) {
        const userPreferencesStore = useUserPreferencesStore();
        if (preferences.tableFilter !== undefined) {
          userPreferencesStore.setComponentsTableFilterStore(preferences.tableFilter);
        }
        if (preferences.itemsPerPage !== undefined) {
          userPreferencesStore.setComponentsTableItemsPerPage(preferences.itemsPerPage);
        }
      },

      restoreUserPreferences() {
        const userPreferencesStore = useUserPreferencesStore();
        this.tableFilter = userPreferencesStore.pageTableFilters.componentsTableFilterStore;
        this.itemsPerPage = userPreferencesStore.itemsPerPage.componentsTableItemsPerPage;
      },
    },
  });
</script>

<style scoped>
  .v-data-table {
    margin-top: 20px;
  }
  .controls-wrapper {
    display: flex;
    justify-content: space-around;
    font-size: 18px;
  }
  .controls-wrapper span {
    cursor: pointer;
  }
  .controls-wrapper span > i:hover {
    color: orange;
  }
  .controls-wrapper span > i.fa-pen-to-square:hover {
    color: rgb(64, 64, 194);
  }
  .controls-wrapper span > i.fa-trash:hover {
    color: red;
  }
  .filter-select {
    margin: -9px 0px 0px 0px;
  }
  :deep(.v-table thead) {
    background-color: #f5f5f5;
  }
</style>
