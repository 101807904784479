<template>
  <v-row class="d-inline-flex align-items-center flex-nowrap d">
    <div :class="['wrapper', { depth: depth > 0 }]">
      <v-col cols="auto" class="d-inline-flex align-items-center icons-content" style="position: relative">
        <div class="formula-icon">
          <div v-if="selectedStart === 'Function'">
            <i class="fa-solid fa-f"></i>
            <span class="reset-to-start" @click="reset2start">x</span>
          </div>
          <div v-if="selectedStart === 'Attribute'">
            <i class="fa-solid fa-a"></i>
            <span class="reset-to-start" @click="reset2start">x</span>
          </div>
          <div v-if="selectedStart === 'Value'">
            <i class="fa-solid fa-v"></i>
            <span class="reset-to-start" @click="reset2start">x</span>
          </div>
        </div>
      </v-col>

      <v-col cols="auto" v-if="!selectedStart" class="d-inline-flex align-items-center select-content">
        <v-select label="Select" :items="startOptions" v-model="selectedStart" variant="underlined" class="mr-2" clearable></v-select>
      </v-col>

      <v-col cols="auto" v-if="selectedStart === 'Function'" class="d-inline-flex align-items-center function-content">
        <v-select label="Function" :items="functionsOptions" v-model="selectedFunction" item-title="name" variant="underlined" class="mr-2" clearable></v-select>
      </v-col>

      <v-col cols="auto" v-if="selectedStart === 'Attribute'" class="d-inline-flex align-items-center attribute-content">
        <v-select
          label="Attribute"
          :items="attributeOptions"
          v-model="selectedAttribute"
          item-title="name"
          item-value="id"
          return-object
          variant="underlined"
          class="mr-2"
          clearable
        ></v-select>
      </v-col>

      <v-col cols="auto" v-if="selectedStart === 'Value'" class="d-inline-flex align-items-center value-content">
        <v-text-field
          label="Value"
          v-model="selectedValue"
          variant="underlined"
          class="mr-2"
          clearable
          style="min-width: 100px"
          :error-messages="valueError"
          @input="validateValue"
        ></v-text-field>
      </v-col>

      <v-col cols="auto" v-if="shouldRecurse" class="d-inline-flex align-items-center quotes-content" :style="{ color: randomColor }">
        <div v-if="!isItLookUpFunction" class="recurse-formula d-inline-flex align-items-center">
          <span v-if="usesParentheses" class="formula-quote">(</span>

          <RecursiveFormulaComponent
            v-if="shouldRecurse"
            :start-options="startOptionA"
            :functions-options="functionsOptions"
            :attribute-options="attributeOptions"
            :key="`${id}-first`"
            v-model="formulaData.inputA"
            :depth="depth + 1"
          />

          <span v-if="usesParentheses" class="formula-comma">,</span>

          <RecursiveFormulaComponent
            v-if="shouldRecurse"
            :start-options="startOptionB"
            :functions-options="functionsOptions"
            :attribute-options="attributeOptions"
            :key="`${id}-second`"
            v-model="formulaData.inputB"
            :depth="depth + 1"
          />

          <span v-if="usesParentheses" class="formula-quote">)</span>
        </div>

        <div v-else-if="isItLookUpFunction" class="recurse-formula d-inline-flex align-items-center">
          <span v-if="usesParentheses" class="formula-quote">(</span>

          <RecursiveFormulaComponent
            v-if="shouldRecurse"
            :start-options="startOptionA"
            :functions-options="functionsOptions"
            :attribute-options="attributeOptions"
            :key="`${id}-first`"
            v-model="formulaData.inputA"
            :depth="depth + 1"
          />
          <span v-if="usesParentheses" class="formula-quote">)</span>
        </div>
      </v-col>
    </div>
  </v-row>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { v4 as uuidv4 } from 'uuid';

  export default defineComponent({
    name: 'RecursiveFormulaComponent',
    props: {
      startOptions: {
        type: Array as PropType<string[]>,
        required: true,
      },
      functionsOptions: {
        type: Array as PropType<{ name: string; arguments: string[][]; description: string; type: string }[]>,
        required: true,
      },
      attributeOptions: {
        type: Array as PropType<{ id: string; name: string }[]>,
        required: true,
      },
      modelValue: {
        type: Object as PropType<any>,
        required: true,
        default: () => ({}),
      },
      depth: {
        type: Number,
        required: true,
        default: 0,
      },
    },
    data() {
      return {
        startOptionA: this.startOptions,
        startOptionB: this.startOptions,
        selectedStart: this.modelValue.start || '',
        selectedFunction: this.modelValue.function || '',
        selectedAttribute: this.modelValue.attribute || '',
        selectedValue: this.modelValue.value || null,
        id: uuidv4(),
        valueError: '',
      };
    },
    computed: {
      shouldRecurse(): boolean {
        return this.selectedFunction !== '';
      },
      usesParentheses(): boolean {
        return ['Function', 'Attribute'].includes(this.selectedStart);
      },
      formulaData: {
        get(): any {
          return this.modelValue;
        },
        set(value: any) {
          this.$emit('update:modelValue', value);
        },
      },
      randomColor(): string {
        const colors = ['#FF6F61', '#6B5B95', '#88B04B', '#34495E', '#92A8D1', '#000000'];
        const index = Math.floor(Math.random() * colors.length);
        return colors[index];
      },
      isItLookUpFunction(): boolean {
        const lookupFunctions = this.functionsOptions.filter((obj) => obj.type === 'Lookup').map((obj) => obj.name);
        return lookupFunctions.includes(this.selectedFunction);
      },
      isFormulaValid(): boolean {
        return this.validateFormulaStructure(this.formulaData);
      },
    },
    watch: {
      selectedStart() {
        this.updateFormula();
      },
      selectedFunction() {
        this.updateFormula();
      },
      selectedAttribute() {
        this.updateFormula();
      },
      selectedValue() {
        this.updateFormula();
      },
      formulaData: {
        deep: true,
        handler(newValue) {
          this.$emit('formula-changed', {
            formulaEmit: newValue,
            isValid: this.isFormulaValid,
          });
        },
      },
    },
    methods: {
      validateFormulaStructure(formula: any, depth: number = 0): boolean {
        if (depth > 100) {
          console.error('Maximum recursion depth exceeded');
          return false;
        }

        if (!formula || !formula.start) {
          return false;
        }

        const validators = {
          Value: this.validateValueFormula,
          Attribute: this.validateAttribute,
          Function: this.validateFunction,
        };

        const validator = validators[formula.start as keyof typeof validators];
        return validator ? validator(formula, depth) : false;
      },
      validateValueFormula(formula: any): boolean {
        return this.isValidValue(formula.value);
      },
      validateAttribute(formula: any): boolean {
        return !!formula.attribute;
      },
      validateFunction(formula: any, depth: number): boolean {
        if (!formula.function) {
          return false;
        }

        if (this.checkLookUpFunction(formula.function)) {
          return this.validateLookupFunction(formula);
        } else {
          return this.validateRegularFunction(formula, depth);
        }
      },
      validateLookupFunction(formula: any): boolean {
        return formula.inputA && formula.inputA.start === 'Attribute' && !!formula.inputA.attribute;
      },
      validateRegularFunction(formula: any, depth: number): boolean {
        return formula.inputA && this.validateFormulaStructure(formula.inputA, depth + 1) && formula.inputB && this.validateFormulaStructure(formula.inputB, depth + 1);
      },
      isValidValue(value: any): boolean {
        return value !== null && value !== undefined && value !== '' && !isNaN(Number(value));
      },
      checkLookUpFunction(functionName: string): boolean {
        return this.functionsOptions.some((func) => func.name === functionName && func.type === 'Lookup');
      },
      validateValue() {
        this.valueError = '';
        if (Number(this.selectedValue)) {
          this.valueError = '';
          return true;
        }
        if (isNaN(Number(this.selectedValue))) {
          this.valueError = 'Value must be a number';
          return false;
        }
        if (!this.selectedValue || this.selectedValue.trim() === '') {
          this.valueError = 'The value cannot be empty';
          return false;
        }
        return true;
      },
      updateFormula() {
        let formula = {};
        this.updateSelection();
        if (this.selectedStart === 'Function') {
          formula = {
            start: 'Function',
            function: this.selectedFunction,
            inputA: this.formulaData.inputA || {},
            inputB: this.formulaData.inputB || {},
          };
        } else if (this.selectedStart === 'Attribute') {
          formula = {
            start: 'Attribute',
            attribute: this.selectedAttribute,
          };
        } else if (this.selectedStart === 'Value') {
          formula = {
            start: 'Value',
            value: this.selectedValue,
          };
        }

        this.formulaData = formula;
      },
      updateSelection() {
        if (this.functionsOptions && this.selectedFunction) {
          const foundOption = this.functionsOptions.find((func) => func.name === this.selectedFunction);
          if (foundOption) {
            const allArguments = foundOption.arguments.map((subArray) => subArray.map((item) => (item === 'NumberValue' || item === 'StringValue' ? 'Value' : item)));
            this.startOptionA = allArguments[0];
            this.startOptionB = allArguments[1];
          }
        }
      },
      reset2start() {
        this.selectedStart = '';
        this.selectedFunction = '';
        this.selectedAttribute = '';
        this.selectedValue = '';
        this.updateFormula();
      },
    },
    mounted() {
      this.$emit('formula-changed', {
        formulaEmit: this.formulaData,
        isValid: this.isFormulaValid,
      });
    },
  });
</script>

<style scoped>
  .formula-quote {
    font-size: 49px;
  }
  .formula-comma {
    margin-left: 4px;
    margin-right: 4px;
    font-size: 49px;
  }
  .formula-icon {
    font-size: 49px;
  }
  .flex-nowrap {
    flex-wrap: nowrap;
  }
  .wrapper {
    padding: 0px 13px;
    display: inline-flex;
    overflow: auto;
  }
  .wrapper.depth {
    padding: 0px 13px;
    overflow: hidden;
    width: max-content;
  }
  .reset-to-start {
    font-size: 14px;
    top: 10px;
    position: absolute;
    right: -8px;
    cursor: pointer;
  }
  .quotes-content,
  .function-content,
  .attribute-content,
  .value-content {
    padding: 12px 0px 12px 8px;
  }
  .icons-content,
  .select-content {
    padding: 12px 0px 12px 0px;
  }
</style>
