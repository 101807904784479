<!-- src/views/Landing.vue -->
<template>
  <v-container>
    <v-main>
      <v-row>
        <v-col cols="12">
          <h1>Welcome to the Landing Page</h1>
        </v-col>
      </v-row>
    </v-main>
  </v-container>
</template>

<script lang="ts">
  import { defineComponent, onMounted } from 'vue';
  import { useRouter } from 'vue-router';

  export default defineComponent({
    name: 'Landing',
    setup() {
      const router = useRouter();

      onMounted(() => {
        router.push({ name: 'Attributes' });
      });
    },
  });
</script>
