<!-- src/views/user/Login.vue -->
<template>
  <v-container>
    <v-form @submit.prevent="login">
      <v-text-field v-model="username" label="Username" required></v-text-field>
      <v-text-field v-model="password" label="Password" type="password" required></v-text-field>
      <v-btn type="submit">Login</v-btn>
    </v-form>
  </v-container>
</template>

<script lang="ts">
  import { defineComponent, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import apiClient from '@/services/axios';

  export default defineComponent({
    name: 'Login',
    setup() {
      const router = useRouter();
      const username = ref('');
      const password = ref('');

      const login = async () => {
        try {
          const response = await apiClient.post('/login', {
            username: username.value,
            password: password.value,
          });
          router.push('/landing');
        } catch (error) {
          router.push('/landing');
          console.error('Login failed', error);
        }
      };

      return {
        username,
        password,
        login,
      };
    },
  });
</script>
