<!-- src/views/user/Profile.vue -->
<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-card>
          <v-card-title class="headline">Profile</v-card-title>
          <v-card-text>
            <v-avatar color="brown" size="large" class="mx-auto my-3">
              <span class="text-h5">{{ initials }}</span>
            </v-avatar>
            <div class="text-center">
              <h3>{{ user?.name }}</h3>
              <p class="text-caption mt-1">{{ user?.email }}</p>
            </div>
            <v-divider class="my-3"></v-divider>
            <div v-if="user">
              <p>
                <strong>Nickname:</strong>
                {{ user.nickname }}
              </p>
              <p>
                <strong>Updated At:</strong>
                {{ user.updated_at }}
              </p>
              <p>
                <strong>Sub:</strong>
                {{ user.sub }}
              </p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
  import { defineComponent, computed } from 'vue';
  import { useAuth0 } from '@auth0/auth0-vue';

  export default defineComponent({
    name: 'Profile',
    setup() {
      const { user } = useAuth0();

      const initials = computed(() => {
        if (user.value && user.value.name) {
          const names = user.value.name.split(' ');
          return names.map((n) => n[0]).join('');
        }
        return '';
      });

      return {
        user,
        initials,
      };
    },
  });
</script>

<style scoped>
  .text-center {
    text-align: center;
  }
</style>
