<!-- src/views/Settings.vue -->
<template>
  <v-container>
    <h1>Settings</h1>
    <hr class="mb-5" />
    <v-row>
      <v-col>
        <v-btn @click="migrationStart" color="warning">Start PM Migration</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import apiClient from '@/services/axios';

  interface Item {
    id: string;
    value: string;
    linkto?: string;
    desc: string;
  }

  type LayerKeys = 'start' | 'function' | 'attribute';

  export default defineComponent({
    name: 'Test',

    data() {
      return {};
    },

    methods: {
      async migrationStart() {
        try {
          const response = await apiClient.get('/pg/SyncFromPG');
          this.$swal.fire('Success', `Migration done: ${JSON.stringify(response.data)}`, 'success');
        } catch (error) {
          this.$log.error('Error fetching data:', error);
          this.$swal.fire('Error', 'Migration failed. Please check the logs for more details.', 'error');
        }
      },
    },

    mounted() {},
  });
</script>
