<!-- src/views/boms/Boms.vue -->
<template>
  <v-container fluid data-cy="container" style="padding-top: 0px">
    <v-card class="pa-3" style="border: none" data-cy="main-card">
      <v-tabs v-model="tab" style="flex-grow: 1; border-bottom: 2px solid" data-cy="tabs">
        <v-tab :key="0" :active="activeTab === 0" @click="activeTab = 0" data-cy="tab-saved">
          <i class="fa-solid fa-list"></i>
          &nbsp; Saved BOMs
        </v-tab>
        <v-tab :key="1" :active="activeTab === 1" @click="activeTab = 1">
          <i class="fa-solid fa-file-certificate"></i>
          &nbsp; Certified Products
        </v-tab>
        <v-spacer></v-spacer>
        <v-text-field
          class="mr-3"
          max-width="200"
          v-model="search"
          density="compact"
          label="Search"
          prepend-inner-icon="mdi-magnify"
          variant="underlined"
          hide-details
          single-line
          data-cy="search-field"
        ></v-text-field>
        <v-btn class="btn-orange save-to-reference" @click="openImportModal" data-cy="import-button">
          <i class="fa-solid fa-download mr-2"></i>
          Import from Odoo
        </v-btn>
      </v-tabs>
      <div v-if="tab === 0" data-cy="tab-content-saved">
        <v-data-table
          :headers="headers"
          :items="filteredItems"
          class="custom-table"
          data-cy="data-table"
          loading-text="Loading... Please wait"
          :loading="loadingData"
          v-model:items-per-page="itemsPerPage"
          :items-per-page-options="itemsPerPageOptions"
          :sort-by="[{ key: 'updatedAt', order: 'desc' }]"
        >
          <template v-slot:item.productInfo="{ item }">
            <span style="text-wrap: nowrap">{{ item.productPn }}</span>
          </template>

          <template v-slot:item.reference="{ item }">
            <span>{{ item.reference }}</span>
          </template>

          <template v-slot:item.referenceType="{ item }">
            <v-chip :color="getStatusColor(item.referenceType)" :class="getStatusClass(item.referenceType)" size="small" class="text-caption">
              {{ capitalizeFirst(item.referenceType) }}
            </v-chip>
          </template>

          <template v-slot:item.createdBy="{ item }">
            {{ formatUnknown(item.createdBy) }}
          </template>

          <template v-slot:item.updatedAt="{ item }">
            <div class="d-flex flex-column">
              <span class="text-caption">Created: {{ formatDate(item.createdAt) }}</span>
              <span class="text-caption text-grey">Last updated: {{ updateEventFormatDate(item.updatedAt) }}</span>
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <div class="controls-wrapper">
              <span @click="openModal('edit', item)"><i class="fa-duotone fa-solid fa-pen-to-square"></i></span>
              <span @click="confirmDelete(item)"><i class="fa-duotone fa-solid fa-trash"></i></span>
            </div>
          </template>
        </v-data-table>
      </div>
      <div v-if="tab === 1" data-cy="tab-content-certified">
        <CertifiedProductFamilies
          :families="certifiedFamilies"
          :loading="loadingData"
          @open-modal="openModal"
          @confirm-delete="confirmDelete"
          @update:revoke-dialog="revokeDialog = $event"
          @confirmRevoke="handleRevokeConfirm"
        />
      </div>

      <BomsModal :modal="modal" :modalTitle="modalTitle" :bomData="bomData" :uomOptions="uomOptions" :families="certifiedFamilies" @close="closeModal" @save="saveBom" />

      <Dialogs
        :cancelDialog="cancelDialog"
        :deleteDialog="deleteDialog"
        :revokeDialog="revokeDialog"
        @closeCancelDialog="closeCancelDialog"
        @confirmCancel="confirmCancel"
        @closeDeleteDialog="closeDeleteDialog"
        @confirmDeleteItem="confirmDeleteItem"
        @update:revoke-dialog="revokeDialog = $event"
        @confirmRevoke="handleRevokeConfirm"
      />

      <ImportOdooModal :modal="showImportModal" :families="certifiedFamilies" @close="showImportModal = false" @bom-imported="getBomsList" />
    </v-card>
  </v-container>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import bomService from '@/services/bomService';
  import Dialogs from '@/components/Dialogs.vue';
  import BomsModal from './BomsModal.vue';
  import CertifiedProductFamilies from './CertifiedProductFamilies.vue';
  import ImportOdooModal from './ImportOdooModal.vue';

  export default defineComponent({
    name: 'Boms',
    components: {
      Dialogs,
      BomsModal,
      CertifiedProductFamilies,
      ImportOdooModal,
    },

    data() {
      return {
        tab: 0,
        activeTab: 0,
        page: 1,
        search: '',
        cancelDialog: false,
        modal: false,
        viewDialog: false,
        deleteDialog: false,
        loadingData: false,
        saving: false,
        showImportModal: false,
        isFormValid: true,
        selectedBom: null as Bom | null,
        modalTitle: '',
        revokeDialog: false,
        itemToRevoke: null,
        headers: [
          { title: 'Product PN', value: 'productInfo', sortable: false, width: '23%' },
          { title: 'Reference', value: 'reference', sortable: false, width: '20%' },
          { title: 'Reference Type', value: 'referenceType', sortable: false, width: '15%' },
          { title: 'Created By', value: 'createdBy', sortable: false, width: '15%' },
          { title: 'Timestamps', value: 'updatedAt', sortable: true, width: '20%' },
          { title: 'Actions', value: 'actions', sortable: false, width: '10%' },
        ],
        bomsData: [] as Bom[],
        itemToDelete: null as Bom | null,
        itemsPerPage: 10,
        itemsPerPageOptions: [10, 25, 50],
        bomData: {
          productPn: '',
          createdAt: '',
          updatedAt: '',
          referenceType: '',
          description: '',
          bomSource: '',
          routing: '',
          bomLines: [],
        } as Partial<Bom>,
        referenceTypes: ['reference', 'certified', 'validation'],
        bomSources: ['certified', 'odoo', 'generated', 'reference'],
        uomOptions: ['Each', 'Meter', 'Millimeter', 'Foot'],
      };
    },

    computed: {
      filteredItems() {
        let items = this.bomsData.slice();

        if (this.search) {
          const searchTerm = this.search.toLowerCase();
          items = items.filter((item: Bom) => {
            const productPn = item.productPn?.toLowerCase() || '';
            const referenceType = item.referenceType?.toLowerCase() || '';
            const bomSource = item.bomSource?.toLowerCase() || '';
            const routing = item.routing?.toLowerCase() || '';
            const reference = item.reference?.toLowerCase() || '';
            const createdBy = item.createdBy?.toLowerCase() || '';
            const generatedFrom = item.generatedFrom?.toLowerCase() || '';
            const certifiedBy = item.certificationData?.certifiedBy?.toLowerCase() || '';
            const searchableContent = [productPn, referenceType, bomSource, routing, reference, createdBy, generatedFrom, certifiedBy].join(' ');
            return searchableContent.includes(searchTerm);
          });
        }
        return items;
      },
      certifiedFamilies() {
        const certifiedBoms = this.bomsData.filter((bom) => bom.referenceType === 'certified' && bom?.certificationData);

        const groupedBoms = certifiedBoms.reduce(
          (acc, bom) => {
            if (!bom.pnFamily) return acc;

            if (!acc[bom.pnFamily]) {
              acc[bom.pnFamily] = {
                pnFamily: bom.pnFamily,
                bomCount: 0,
                boms: [],
              };
            }

            acc[bom.pnFamily].bomCount++;
            acc[bom.pnFamily].boms.push(bom);

            return acc;
          },
          {} as Record<string, { pnFamily: string; bomCount: number; boms: Bom[] }>
        );
        return Object.values(groupedBoms).sort((a, b) => a.pnFamily.localeCompare(b.pnFamily));
      },
    },

    mounted() {
      this.getBomsList();
    },

    methods: {
      async getBomsList() {
        this.loadingData = true;
        try {
          const boms = await bomService.bomList();
          this.bomsData = boms;
        } catch (error) {
          this.$log.showError(`Failed to fetch BOMs: ${error}`);
        } finally {
          this.loadingData = false;
        }
      },

      formatDate(dateString: string): string {
        return new Date(dateString).toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        });
      },

      updateEventFormatDate(dateString: string): string {
        const date = new Date(dateString);
        const now = new Date();
        if (date.toDateString() === now.toDateString()) {
          return `Today, ${date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
          })}`;
        }

        return date.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: '2-digit',
          hour12: true,
        });
      },

      getStatusColor(status: string): string {
        const statusColors = {
          reference: 'orange', // Light blue background
          certified: 'green', // Light green background
          broken: '#FEE2E2', // Light red background
          validation: 'primary',
        };
        return statusColors[status as keyof typeof statusColors] || '#F3F4F6';
      },

      getStatusClass(status: string): string {
        const statusClasses = {
          reference: 'text-blue-600', // Blue text
          certified: 'text-green-600', // Green text
          broken: 'text-red-600', // Red text
          validation: 'mr-2',
        };
        return statusClasses[status as keyof typeof statusClasses] || 'text-gray-600';
      },

      capitalizeFirst(str: string): string {
        if (!str) return '';
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      },

      openModal(action: 'new' | 'edit', item?: Bom) {
        this.modalTitle = action === 'new' ? 'Create New BOM' : 'Edit BOM';
        if (action === 'edit' && item) {
          this.bomData = {
            ...item,
            bomLines: JSON.parse(JSON.stringify(item.bomLines)),
          };
        } else {
          this.resetBomData();
        }
        this.modal = true;
      },

      resetBomData() {
        this.bomData = {
          productPn: '',
          referenceType: '',
          bomSource: '',
          routing: '',
          bomLines: [],
        };
      },

      closeModal() {
        this.getBomsList();
        this.modal = false;
        this.resetBomData();
      },

      closeViewDialog() {
        this.viewDialog = false;
        this.selectedBom = null;
      },

      deleteBom(item: Bom) {
        this.itemToDelete = item;
        this.deleteDialog = true;
      },

      confirmDelete(item: Bom) {
        this.itemToDelete = item;
        this.deleteDialog = true;
      },

      closeDeleteDialog() {
        this.deleteDialog = false;
        this.itemToDelete = null;
      },

      showSuccessMessage(message: string) {
        this.$swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: message,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      },

      confirmCancel() {
        this.closeModal();
        this.cancelDialog = false;
      },
      closeCancelDialog() {
        this.cancelDialog = false;
      },

      async confirmDeleteItem() {
        if (!this.itemToDelete) return;
        try {
          await bomService.bomDelete(this.itemToDelete.id);
          this.bomsData = this.bomsData.filter((item) => item.id !== this.itemToDelete?.id);
          this.showSuccessMessage('BOM successfully deleted');
          this.closeDeleteDialog();
        } catch (error) {
          this.$log.showError(`Failed to delete BOM: ${error}`);
        }
      },

      // async saveBom(updatedBom: Partial<Bom>) {
      //   try {
      //     if (updatedBom.id) {
      //       await bomService.bomUpdate(updatedBom.id, updatedBom);
      //       this.showSuccessMessage('BOM successfully updated');
      //     }
      //     await this.getBomsList();
      //     this.closeModal();
      //   } catch (error) {
      //     this.$log.showError(`Failed to save BOM: ${error}`);
      //   }
      // },
      saveBom() {
        this.showSuccessMessage('BOM successfully saved');
        this.getBomsList();
        this.closeModal();
      },

      formatUnknown(user: string) {
        if (user === 'Unknown') return '-';
        return user;
      },

      openImportModal() {
        this.showImportModal = true;
      },
      confirmRevoke(item: any) {
        this.itemToRevoke = item;
        this.revokeDialog = true;
      },
      handleRevokeConfirm() {
        if (this.itemToRevoke) {
          this.revoke(this.itemToRevoke);
        }
        this.revokeDialog = false;
        this.itemToRevoke = null;
      },
      revoke(item: any) {
        delete item.certificationData;
        if (item.referenceType) {
          item.referenceType = 'reference';
        }
      },
    },
  });
</script>

<style scoped>
  .v-data-table {
    margin-top: 20px;
  }

  .modal-title {
    background-color: #3b516b;
    color: white;
  }

  .custom-table .v-data-table__wrapper {
    overflow-x: auto;
  }

  .custom-table th,
  .custom-table td {
    max-width: 20%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  .uom-select-header {
    background-color: #f5f5f5;
    color: #000;
    font-weight: bold;
  }

  .controls-wrapper {
    padding: 0 8px;
    gap: 16px;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
  }
  .controls-wrapper span {
    cursor: pointer;
  }
  .controls-wrapper span > i:hover {
    color: orange;
  }
  .controls-wrapper span > i.fa-pen-to-square:hover {
    color: rgb(64, 64, 194);
  }
  .controls-wrapper span > i.fa-trash:hover {
    color: red;
  }
  :deep(.v-table thead) {
    background: #f5f5f5;
  }
  .action-buttons {
    padding: 0 8px;
    gap: 16px;
  }
</style>
