<template>
  <v-row>
    <v-col cols="12" md="4" class="mt-2">
      <h4>Target BoM</h4>
      <h3>{{ generation_item }}</h3>
      <!-- <pre> {{ targetBom }}</pre> -->
    </v-col>
    <v-col cols="12" md="3" class="mt-4"></v-col>
    <v-col cols="12" md="5" class="mt-2">
      <h4 class="mb-2">Reference BoM</h4>
      <v-row>
        <v-col cols="12">
          <v-select :items="sourceList" v-model="selectedSource" variant="underlined" density="compact"></v-select>
        </v-col>
      </v-row>
      <v-row style="margin-top: -30px">
        <v-col cols="8">
          <v-text-field v-model="selectedReference" variant="underlined" density="compact"></v-text-field>
        </v-col>
        <v-col cols="4" class="text-right">
          <v-btn disabled style="width: 100%" class="btn-orange" @click="SearchReference">Search</v-btn>
        </v-col>
      </v-row>
      <v-row style="margin-top: -30px">
        <v-col cols="8">
          <v-select :items="routingList" v-model="selectedRouting" variant="underlined" density="compact"></v-select>
        </v-col>
        <v-col cols="4" class="text-right">
          <v-btn disabled style="width: 100%" class="btn-orange" @click="compare">Compare</v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <v-divider :thickness="2" class="mb-3"></v-divider>
  <v-row>
    <v-container class="comparison-status" max-width="600">
      <v-card outlined rounded>
        <v-card-title class="comparison-card">
          <div class="d-flex align-center justify-space-between w-100">
            <span class="comparison-title">
              Comparison Status
              <v-chip color="green" text-color="white" variant="flat" class="status-chip">{{ identicalLines === totalLines ? 'Identical' : 'Different' }}</v-chip>
            </span>
            <span v-if="isCollapsed" class="comparison-title-details">
              <span>
                {{ identicalLines }}/{{ totalLines }}
                <span class="small-text">Identical BoM Lines</span>
              </span>
              |
              <span>
                {{ similarLines }}/{{ totalLines }}
                <span class="small-text">Similar BoM Lines</span>
              </span>
              |
              <span>
                {{ differentLines }}/{{ totalLines }}
                <span class="small-text">Different BoM Lines</span>
              </span>
            </span>
            <v-icon @click="isCollapsed = !isCollapsed" style="cursor: pointer">
              {{ isCollapsed ? 'mdi-chevron-down' : 'mdi-chevron-up' }}
            </v-icon>
          </div>
        </v-card-title>

        <v-expand-transition>
          <v-card-text v-show="!isCollapsed" class="comparison-content" style="margin-top: 0px; padding: 0px">
            <v-row class="d-flex justify-center align-center mt-1">
              <v-col cols="2">
                <div class="comparison-item">Identical BoM Lines</div>
              </v-col>
              <v-col cols="3">
                <div class="comparison-value">
                  {{ identicalLines }}/{{ totalLines }}
                  <span class="comparison-value-desc">Identical</span>
                </div>
              </v-col>
              <v-col cols="3" class="text-start">
                <div class="comparison-percentage">
                  {{ Math.round((identicalLines / totalLines) * 100) }}%
                  <span class="comparison-value-desc">Identical</span>
                </div>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-center align-center">
              <v-col cols="2">
                <div class="comparison-item">Similar BoM Lines</div>
              </v-col>
              <v-col cols="3">
                <div class="comparison-value">
                  {{ similarLines }}/{{ totalLines }}
                  <span class="comparison-value-desc">with different values</span>
                </div>
              </v-col>
              <v-col cols="3" class="text-start">
                <div class="comparison-percentage">
                  {{ Math.round((similarLines / totalLines) * 100) }}%
                  <span class="comparison-value-desc">Similar</span>
                </div>
              </v-col>
            </v-row>

            <v-row class="d-flex justify-center align-center mb-2">
              <v-col cols="2">
                <div class="comparison-item">Different BoM Lines</div>
              </v-col>
              <v-col cols="3">
                <div class="comparison-value">
                  {{ differentLines }}/{{ totalLines }}
                  <span class="comparison-value-desc">with different components</span>
                </div>
              </v-col>
              <v-col cols="3" class="text-start">
                <div class="comparison-percentage text-muted">
                  <!-- <n-progress type="circle" :percentage="Math.round((differentLines / totalLines) * 100)" /> -->

                  {{ Math.round((differentLines / totalLines) * 100) }}%
                  <span class="comparison-value-desc">Different</span>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-expand-transition>
      </v-card>
    </v-container>
  </v-row>
  <v-divider :thickness="2" class="mt-4 mb-3"></v-divider>
  <v-row>
    <v-col cols="12">
      <v-btn-toggle v-model="viewMode" class="my-4" mandatory>
        <v-btn value="line-by-line" :class="viewMode === 'line-by-line' ? 'selected-toggle' : ''">Line-by-line</v-btn>
        <v-btn value="only-differences" :class="viewMode === 'only-differences' ? 'selected-toggle' : ''">Only Differences</v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col cols="12" md="6" style="padding-right: 0px; padding-top: 0px">
      <p class="table-title">Target BoM Lines</p>
      <v-data-table :headers="targetHeaders" :items="targetBom" class="elevation-1" hide-default-footer density="compact">
        <template v-slot:body="{ items }">
          <tr v-for="(item, index) in items" :key="index">
            <td>
              <n-tooltip trigger="hover">
                <template #trigger>
                  <span>{{ (item as BomLine).materials?.pn }}</span>
                </template>
                {{ (item as BomLine).materials?.description }}
              </n-tooltip>
            </td>
            <td>{{ (item as BomLine).cutSize }}</td>
            <td>{{ (item as BomLine).cutUom }}</td>
            <td>{{ (item as BomLine).cutQuantity }}</td>
            <td>{{ (item as BomLine).quantity }}</td>
            <td>{{ (item as BomLine).uom }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-col>

    <v-col cols="12" md="6" style="padding-left: 0px; padding-top: 0px">
      <p class="table-title">Reference BoM Lines</p>
      <v-data-table :headers="referenceHeaders" :items="referenceBomLines" class="elevation-1" hide-default-footer density="compact"></v-data-table>
    </v-col>
  </v-row>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';

  interface BomLine {
    materials: { pn: string; description: string };
    cutSize: string;
    cutUom: string;
    cutQuantity: number;
    quantity: number;
    uom: string;
  }
  export default defineComponent({
    name: 'GenerateCompare',
    props: {
      currentBom: {
        type: Array as PropType<BomLine[]>,
        required: false,
        default: () => [],
      },
      generation_item: {
        type: String,
        required: false,
        default: () => '',
      },
      loadingData: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    data() {
      return {
        sourceList: ['Odoo13', 'Odoo17', 'Certified'],
        selectedSource: 'Odoo13',
        selectedReference: this.generation_item,
        routingList: ['Production Rev 01', 'Production Rev 02', 'Production Rev 03'],
        selectedRouting: 'Production Rev 01',
        isCollapsed: true,
        viewMode: 'line-by-line',
        identicalLines: 20,
        totalLines: 20,
        similarLines: 5,
        differentLines: 1,
        targetHeaders: [
          { title: 'Component', value: 'materials', sortable: false, width: '30%' },
          { title: 'Cut Size', value: 'cutSize', sortable: false, width: '5%' },
          { title: 'Cut UoM', value: 'cutUom', sortable: false, width: '13%' },
          { title: 'Cuts Num', value: 'cutQuantity', sortable: false, width: '5%' },
          { title: 'QTY', value: 'quantity', sortable: false, width: '10%' },
          { title: 'UOM', value: 'uom', sortable: false, width: '13%' },
        ],

        targetBomLines: [],

        referenceHeaders: [
          { title: 'Component', value: 'materials', sortable: false, width: '25%' },
          { title: 'Cut Size', value: 'cutSize', sortable: false, width: '10%' },
          { title: 'Cut UoM', value: 'cutUom', sortable: false, width: '13%' },
          { title: 'Cuts Num', value: 'cutQuantity', sortable: false, width: '10%' },
          { title: 'QTY', value: 'quantity', sortable: false, width: '10%' },
          { title: 'UOM', value: 'uom', sortable: false, width: '13%' },
        ],

        referenceBomLines: [],
      };
    },
    computed: {
      targetBom() {
        return this.currentBom.map((item: any) => {
          const newItem = { ...item };
          if (Array.isArray(newItem.materials) && newItem.materials.length > 0) {
            newItem.materials = newItem.materials[0];
          }
          return newItem;
        });
      },
    },
    methods: {
      SearchReference() {
        console.log('SearchReference');
      },
      compare() {
        this.identicalLines = 18;
        this.similarLines = 2;
        this.differentLines = 0;
        this.totalLines = 20;
      },
    },
  });
</script>

<style scoped>
  .comparison-item {
    font-size: 16px;
    font-weight: 600;
    color: #000000;
  }
  .comparison-value {
    font-size: 16px;
    font-weight: 600;
    color: #000000;
  }
  .comparison-percentage {
    font-size: 16px;
    font-weight: 600;
    color: #000000;
  }
  .comparison-value-desc {
    font-size: 12px;
    color: #6c757d;
  }

  .selected-toggle {
    background-color: #3d4e65 !important;
    color: white !important;
    border-radius: 50px;
  }

  .v-btn-toggle > .v-btn {
    min-width: 150px;
    font-size: 16px;
    font-weight: 500;
    background-color: #f1f2f3;
    color: #333333;
    border-radius: 0px;
  }
  :deep(.v-table thead) {
    background-color: #f5f5f5;
  }
  .table-title {
    font-size: 18px;
    font-weight: 600;
    color: #000000;
  }
  .small-text {
    font-size: 12px;
    color: #6c757d;
  }
  .comparison-title-details {
    font-size: 18px;
    font-weight: 600;
    color: #000000;
    position: absolute;
    right: 60px;
  }
</style>
