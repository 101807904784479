<!-- src/layouts/MainLayout.vue -->
<template>
  <v-app>
    <v-main>
      <TopMenu />
      <LeftSideBar />
      <router-view />
      <Footer />
    </v-main>
  </v-app>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import TopMenu from '@/components/TopMenu.vue';
  import LeftSideBar from '@/components/LeftSideBar.vue';
  import Footer from '@/components/Footer.vue';

  export default defineComponent({
    name: 'MainLayout',
    components: {
      TopMenu,
      LeftSideBar,
      Footer,
    },
  });
</script>

<style scoped>
  /* Scoped styles for MainLayout */
</style>
