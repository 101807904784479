<!-- src/components/Footer.vue -->
<template>
  <footer class="footer">
    <h5>
      Powered by
      <v-icon style="color: #ff9616">mdi-heart</v-icon>
      @ viaPhoton - {{ randomSlogan }}
    </h5>
  </footer>
</template>

<script lang="ts">
  import { defineComponent, computed } from 'vue';
  import { useTheme } from 'vuetify';

  export default defineComponent({
    name: 'Footer',
    setup() {
      const theme = useTheme();
      const isDark = computed(() => theme.global.name.value === 'dark');

      const slogans = ['#ForwardTogether', '#NimbleAlways', '#FearlessInnovation', '#BeTheBar', '#IntegrityOverEverything'];

      const getRandomSlogan = () => {
        const index = Math.floor(Math.random() * slogans.length);
        return slogans[index];
      };

      const randomSlogan = computed(() => getRandomSlogan());
      return {
        isDark,
        randomSlogan,
      };
    },
  });
</script>

<style scoped>
  .footer {
    width: 100%;
    /* position: absolute; */
    bottom: -52px;
    height: 52px;
    left: 0;
    text-align: center;
    background-color: var(--v-theme-background-base);
    padding: 1rem;
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
    color: var(--footer-font-color);
  }

  .footer[data-theme='dark'] {
    --footer-font-color: black;
  }

  .footer[data-theme='light'] {
    --footer-font-color: inherit;
  }
</style>
