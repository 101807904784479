<template>
  <v-dialog v-model="modal" max-width="80%" data-cy="compare-modal" persistent>
    <v-card>
      <v-card-title class="modal-title" data-cy="compare-modal-title">
        <span class="text-h5">Compare BOM Items</span>
        <v-icon small @click="closeModal" style="right: 10px; top: 10px; position: absolute">mdi-close</v-icon>
      </v-card-title>

      <!-- {{ item }} -->
      <div style="padding: 20px">
        <compare :current-bom="item" :generation_item="selectedPn" :referenceBomPn="referenceBomPn" />
      </div>
      <v-card-actions class="px-6 pb-4">
        <v-spacer></v-spacer>
        <v-btn class="btn-save" @click="closeModal" data-cy="save-button">Close</v-btn>
      </v-card-actions>
      <!-- {{ referenceBom }}ff -->
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import compare from '@/components/bom/CompareBoms.vue';

  export default defineComponent({
    name: 'BomsCompareModal',
    components: {
      compare,
    },
    props: {
      modal: {
        type: Boolean,
        required: true,
      },
      item: {
        type: Object as PropType<any>,
        required: false,
      },
      selectedPn: {
        type: String,
        required: false,
      },
      referenceBomPn: {
        type: String,
        required: false,
      },
    },
    methods: {
      closeModal() {
        this.$emit('close');
      },
      formatDate(dateString: string): string {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        });
      },
    },
  });
</script>

<style scoped>
  .modal-title {
    background-color: #3b516b;
    color: white;
    padding: 16px 24px;
  }

  .compare-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
  }

  .info-section {
    min-width: 0;
  }

  .field-label {
    color: #64748b;
    font-size: 14px;
    margin-bottom: 4px;
    font-weight: 400;
  }

  .field-value {
    color: #1e293b;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mt-6 {
    margin-top: 24px;
  }

  .btn-close {
    background-color: #cce3ff;
    color: rgb(56, 104, 207);
  }
</style>
