<!-- src/views/user/Logout.vue -->
<template>
  <div>Logging out...</div>
</template>

<script setup lang="ts">
  import { onMounted } from 'vue';
  import { useAuth0 } from '@auth0/auth0-vue';

  const { logout } = useAuth0();

  onMounted(() => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  });
</script>

<style scoped>
  div {
    text-align: center;
    margin-top: 20%;
    font-size: 1.5rem;
    font-weight: bold;
  }
</style>
