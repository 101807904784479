<!-- src/views/boms/BomsEditModal.vue -->
<template>
  <v-dialog v-model="modal" max-width="80%" data-cy="compare-modal" persistent>
    <v-card>
      <v-card-title class="modal-title" data-cy="compare-modal-title">
        <span class="text-h5">Edit BOM Lines</span>
        <v-icon small @click="closeModal" style="right: 10px; top: 10px; position: absolute">mdi-close</v-icon>
      </v-card-title>
      <!-- {{ editableBom }} -->
      <div style="padding: 20px">
        <BomEditTable :viewBom="editableBom" />
      </div>
      <v-card-actions class="px-6 pb-4">
        <v-spacer></v-spacer>
        <v-btn class="btn-cancel" @click="closeModal" data-cy="cancel-btn">Cancel</v-btn>
        <v-btn class="btn-save" @click="save" data-cy="done-btn">Done</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import BomEditTable from '@/components/bom/BomEditTable.vue';

  export default defineComponent({
    name: 'BomsCompareModal',
    components: {
      BomEditTable,
    },
    props: {
      modal: {
        type: Boolean,
        required: true,
      },
      item: {
        type: Object as PropType<any>,
        required: false,
      },
    },
    data: () => ({
      editableBom: [] as any[],
    }),
    watch: {
      item: {
        immediate: true,
        handler(newValue) {
          if (newValue) {
            this.editableBom = JSON.parse(JSON.stringify(newValue)).map((entry: any) => {
              if (entry.material) {
                entry.materials = [entry.material];
              }
              return entry;
            });
          }
        },
      },
    },
    methods: {
      save() {
        const processedBom = this.editableBom.map((entry: any) => {
          if (entry.materials && Array.isArray(entry.materials) && entry.materials.length > 0) {
            entry.material = entry.materials[0];
            delete entry.materials;
          }
          return entry;
        });

        this.$emit('update:item', JSON.parse(JSON.stringify(processedBom)));
        this.closeModal();
      },
      closeModal() {
        this.$emit('close');
      },
    },
  });
</script>

<style scoped>
  .modal-title {
    background-color: #3b516b;
    color: white;
    padding: 16px 24px;
  }

  .compare-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
  }

  .info-section {
    min-width: 0;
  }

  .field-label {
    color: #64748b;
    font-size: 14px;
    margin-bottom: 4px;
    font-weight: 400;
  }

  .field-value {
    color: #1e293b;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mt-6 {
    margin-top: 24px;
  }

  .btn-close {
    background-color: #cce3ff;
    color: rgb(56, 104, 207);
  }
</style>
