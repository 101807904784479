<!-- src/views/NotFound.vue -->
<template>
  <v-container>
    <v-row justify="center" align="center">
      <v-col class="text-center">
        <h1>404 - Page Not Found</h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'NotFound',
  });
</script>
