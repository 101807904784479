<!-- src/views/generate/GenerateViewBom.vue -->
<template>
  <v-row data-cy="tab-content-bom">
    <v-col cols="12" md="12" class="mt-4" v-if="viewBom && viewBom.length">
      <h2 class="mb-3">Product info:</h2>

      <v-row>
        <v-col cols="12" md="12" style="padding: 0px 12px 0px 24px">
          <v-text-field v-model="description" label="Description" variant="underlined"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <!-- <v-col cols="12" md="4" style="padding: 0px 12px 0px 24px">
          <v-select label="Routing" :items="routingList" v-model="selectedRouting" variant="underlined"></v-select>
        </v-col> -->
        <v-col cols="12" md="12" style="padding: 0px 12px 0px 24px">
          <v-text-field v-model="customerDescription" label="Customer Description (optional)" variant="underlined"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" style="padding: 0px 12px 0px 24px">
          <v-select label="Inventory Routing" :items="invRouting" v-model="selectedInvRouting" variant="underlined"></v-select>
        </v-col>
        <v-col cols="12" md="4" style="padding: 0px 12px 0px 24px">
          <v-text-field v-model="customerPN" label="Customer PN (optional)" variant="underlined"></v-text-field>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" md="12" style="padding: 0px 12px">
      <div v-if="viewBom && viewBom.length" class="table-div">
        <h2>BoM:</h2>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="selectedReference" label="Reference" variant="underlined"></v-text-field>
          </v-col>
          <v-col cols="12" md="4" style="padding: 12px 12px 0px 24px">
            <v-select label="Routing" :items="routingList" v-model="selectedRouting" variant="underlined"></v-select>
          </v-col>
        </v-row>
        <div class="button-container">
          <v-btn class="btn-orange save-to-reference" @click="saveBom" data-cy="save-bom">
            <i class="fa-solid fa-cloud-arrow-up"></i>
            &nbsp; Save BOM
          </v-btn>
          <v-btn class="btn-orange save-to-cert" @click="certBom" data-cy="cert-bom">
            <i class="fa-solid fa-file-certificate"></i>
            &nbsp; Certify BoM
          </v-btn>
        </div>
        <BomDataTable :bomList="viewBom" />

        <v-row class="d-flex align-center justify-space-between mt-4 mb-4">
          <v-col cols="auto" class="d-flex align-center"></v-col>
          <v-col cols="auto" class="d-flex align-center">
            <span class="mr-2">Use Odoo v13</span>
            <v-checkbox v-model="legacyOdoo" class="mr-4" data-cy="create-in-odoo" hide-details></v-checkbox>
            <v-btn class="btn-orange" @click="createOdoo" data-cy="create-in-odoo">
              <span v-if="legacyOdoo === false">Create in Odoo v17</span>
              <span v-else>Create in Odoo v13</span>
            </v-btn>
          </v-col>
        </v-row>
      </div>

      <div v-else class="text-center no-data" data-cy="details-content">
        <div v-if="!loadingData">
          <h2 data-cy="details-title">No Product Data</h2>
          <p data-cy="details-description">
            Please write
            <strong>PN</strong>
            and press
            <span class="mini-btn"><strong style="color: orange">GENERATE</strong></span>
            button
          </p>
        </div>
        <v-skeleton-loader v-else type="heading, table-row-divider@6" :loading="loadingData" rows="5" row-height="30" class="mt-5" />
      </div>
    </v-col>
  </v-row>
  <!-- <createNewBom :modal="modal" :modalTitle="modalTitle" :bomData="bomData" :uomOptions="uomOptions" :families="certifiedFamilies" @close="closeModal" @save="saveBom" /> -->

  <createNewBom :modal="modal" :modalTitle="modalTitle" :bomData="viewBomCreate" :families="[]" @close="closeModal" @save="closeModal" />
</template>

<script lang="ts">
  import { defineComponent, PropType, version } from 'vue';
  import odooService from '@/services/odooService';
  import bomService from '@/services/bomService';
  import BomDataTable from '@/components/bom/BomTable.vue';
  import createNewBom from '@/views/boms/BomsModal.vue';
  import { useAuthStore } from '@/store/authStore';

  export default defineComponent({
    name: 'GenerateViewBom',
    components: {
      BomDataTable,
      createNewBom,
    },
    props: {
      viewBom: {
        type: Array as PropType<Array<Record<string, any>>>,
        required: false,
        default: () => [],
      },
      odooAttributes: {
        type: Array as PropType<Array<Record<string, any>>>,
        required: false,
        default: () => [],
      },
      product_info: {
        type: String as PropType<string>,
        required: false,
        default: () => '',
      },
      generation_item: {
        type: String as PropType<string>,
        required: false,
        default: () => '',
      },
      loadingData: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        description: '' as string,
        selectedReference: '',
        routingList: ['Standard'],
        selectedRouting: 'Standard',
        legacyOdoo: true,
        modalTitle: '',
        modal: false,
        viewBomCreate: {},
        invRouting: ['Manufacture (VMTO)', 'Purchase', 'Sub-Contract'],
        selectedInvRouting: 'Manufacture (VMTO)',
        customerPN: '',
        customerDescription: '',
      };
    },
    computed: {},

    mounted() {
      this.getOdooRoutings();
      this.populateReference();
      this.populateDescription();
    },
    watch: {
      product_info(newVal) {
        if (newVal) {
          this.description = newVal.trim() || '';
        }
      },
    },
    methods: {
      populateReference() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        this.selectedReference = `Reference ${month}/${day}/${year}`;
      },
      populateDescription() {
        this.description = this.product_info || '';
      },
      async getOdooRoutings() {
        try {
          const response = await odooService.odooRoutings();
          this.routingList = response.map((item: { name: string }) => item.name).sort((a: string, b: string) => a.localeCompare(b));
        } catch (error) {
          this.$log.showError(`Failed to fetch Odoo routings: ${error}`);
        }
      },

      async saveBom() {
        try {
          const processedBomLines = this.viewBom.map((item) => ({
            ...item,
            material: Array.isArray(item.materials) ? item.materials[0] : item.materials,
          }));

          const data = {
            referenceType: 'reference',
            bomSource: 'generated',
            generatedFrom: this.generation_item,
            description: this.description,
            productPn: this.generation_item,
            reference: this.selectedReference,
            routing: this.selectedRouting,
            bomLines: processedBomLines,
            createdBy: useAuthStore().user?.name || 'Unknown',
            inventoryRouting: this.selectedInvRouting,
            ...(this.customerPN && { customerPn: this.customerPN }),
            ...(this.customerDescription && { customerDescription: this.customerDescription }),
          };

          const response = await bomService.bomCreate(data);

          this.$log.showSuccess('BOM saved To Reference');
        } catch (error) {
          this.$log.showError(`Failed to save BOM: ${error}`);
        }
      },
      saveBomLocal() {
        const processedBomLines = this.viewBom.map((item) => {
          return {
            ...item,
            material: item.materials[0],
          };
        });

        const data = {
          referenceType: 'reference',
          bomSource: 'generated',
          generatedFrom: this.generation_item,
          productPn: this.generation_item,
          reference: this.selectedReference,
          routing: this.selectedRouting,
          bomLines: processedBomLines,
        };

        localStorage.setItem('bom_list', JSON.stringify(data));
        this.$log.showSuccess('BOM saved in LocalStorage');
      },

      async createOdoo() {
        //console.log('Create in Odoo');
        try {
          const data = {
            pn: this.generation_item,
            description: this.description,
            routing: this.selectedRouting,
            reference: this.selectedReference,
            lines: this.viewBom,
            legacyOdoo: this.legacyOdoo,
            attributes: this.odooAttributes,
          };
          //  console.log(data);
          const response = await odooService.createInOdoo(data);
          const success = response?.result?.success;
          const product_id = response?.result?.product_id;
          const message = response?.result?.message;

          if (success) {
            this.showSuccessMessage('Successfully added');
            this.showOdooMessageWithLink(product_id, message);
          } else {
            this.$log.showError(`Failed to create BOM: ${message}`);
          }
        } catch (error: any) {
          this.$log.showError(`Failed to create: ${error.message || error}`);
        }
      },
      showSuccessMessage(message: string) {
        this.$swal.fire({
          toast: true,
          position: 'top-end',
          icon: 'success',
          title: message,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      },
      showOdooMessageWithLink(product_id: number, message: string) {
        const baseUrl = this.legacyOdoo ? 'https://staging.erp.viaphoton.com/web#id=' : 'https://erp17.viaphoton.dev/web#id=';

        const url = `${baseUrl}${product_id}&cids=1&menu_id=${this.legacyOdoo ? 142 : 285}&action=${this.legacyOdoo ? 283 : 436}&model=product.template&view_type=form`;

        const text = `
                          ${message} <br>
                          <a href="${url}" target="_blank">
                            Open in Odoo
                          </a>
                        `;

        this.$swal.fire({
          toast: true,
          position: 'top',
          icon: 'success',
          html: text,
          showConfirmButton: true,
          timer: 60000,
          timerProgressBar: true,
        });
      },
      certBom() {
        this.modal = true;
        this.modalTitle = 'Certify BOM';
        this.viewBomCreate = {
          referenceType: 'reference',
          bomSource: 'generated',
          generatedFrom: this.generation_item,
          description: this.description,
          productPn: this.generation_item,
          reference: this.selectedReference,
          routing: this.selectedRouting,
          bomLines: this.viewBom,
          createdBy: useAuthStore().user?.name || 'Unknown',
          inventoryRouting: this.selectedInvRouting,
          ...(this.customerPN && { customerPn: this.customerPN }),
          ...(this.customerDescription && { customerDescription: this.customerDescription }),
        };
      },
      closeModal() {
        this.modal = false;
      },
    },
  });
</script>

<style scoped>
  .no-data {
    padding-top: 62px;
  }
  .mini-btn {
    font-size: 0.8rem;
    border: 1px solid orange;
    padding: 2px 5px;
    border-radius: 5px;
  }

  ::v-deep(.v-data-table thead) {
    background-color: #f5f5f5;
  }
  ::v-deep(.v-data-table tr:hover) {
    background-color: #f7f7f7;
  }

  .table-div {
    position: relative;
  }
  .table-desc {
    color: #5e5e5e;
    font-size: 14px;
  }
  .button-container {
    display: flex;
    gap: 10px; /* This creates space between the buttons */
    margin-bottom: 16px; /* Add some space below the buttons if needed */
    justify-content: flex-end; /* This aligns buttons to the right */
  }
</style>
